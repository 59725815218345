import { logError } from '@local/error'
import { getApolloClient } from 'cornucopia-apis'

const ooGlobals = window.OO_GLOBALS || {}

export const client = getApolloClient(
  {
    RESTAURANT_GUID: ooGlobals.restaurantGuid,
    GATEWAY_BASE_URI: process.env.GATEWAY_BASE_URI || ooGlobals.gatewayBaseUri,
    BFF_PATH: (() => {
      switch (ooGlobals.mode) {
        case 'OPT':
        case 'TTS':
        case 'STP':
        case 'MNP':
          return '/opt-bff/v1'
        default:
          return '/consumer-app-bff/v1'
      }
    })(),
    BFF_BASE_URI: process.env.BFF_BASE_URI,
    PKG_NAME: process.env.PKG_NAME,
    PKG_VERSION: process.env.PKG_VERSION,
    logError
  },
  true
)
