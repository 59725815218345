import { banquetSingleSpaReact } from 'banquet-runtime-modules'
import { singleSpaCssLifecycles } from '@toasttab/banquet-single-spa-css'
import { AppWrapper } from './app/App'
import { getSentryConfig } from 'do-cornucopia-root'
import './index.css'

const tempWindow: any = window
const sentry = getSentryConfig(tempWindow.OO_GLOBALS.mode)

const reactLifecycles = banquetSingleSpaReact({
  singleSpaCssLifecycles,
  cssScope: 'data-corn-loyalty-spa',
  rootComponent: AppWrapper,
  portalContainers: ['banquetPortalsContainer'],
  sentry
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'corn-loyalty-spa'
