import React from 'react'
import cx from 'classnames'

import { Loading, LoadingVariant } from '@local/do-secundo-loading'
import { BIRTHDAY_REDEMPTION_TYPE } from '../../../constants'

import {
  CancelIcon,
  GiftCardIcon,
  DiamondIcon
} from '@toasttab/buffet-pui-icons'

import styles from './LoyaltyRedemption.module.css'

import { PartyMember, RedemptionData } from '../../types'
import { useToggleRedemption } from './use-toggle-redemption'
import { ErrorComponent } from '@local/error'
import { LockedLoyaltyRedemption } from '../LockedLoyaltyRedemption/LockedLoyaltyRedemption'

/**
 * Displays and allows redemption of a loyalty reward.
 */
export const LoyaltyRedemption = ({
  partyMember,
  partyGuid,
  redemption,
  memberAuthToken,
  onLoyaltyUpdate,
  mode
}: {
  partyMember: PartyMember
  partyGuid: number
  redemption: RedemptionData
  memberAuthToken: string
  onLoyaltyUpdate: () => void
  mode: string
}) => {
  const { toggleRedemption, isLoading, isApplied, error } = useToggleRedemption(
    redemption,
    partyMember.appliedRewards,
    onLoyaltyUpdate,
    mode
  )

  if (redemption?.currentState?.leftToTarget > 0) {
    return <LockedLoyaltyRedemption redemption={redemption} />
  }

  const redemptionType = redemption.referenceId

  const description = redemption?.currentState?.messages?.rewardLabelMessage

  const value = redemption?.currentState?.messages?.rewardValueMessage

  const icon =
    redemptionType === BIRTHDAY_REDEMPTION_TYPE ? (
      <GiftCardIcon />
    ) : (
      <DiamondIcon />
    )

  const toggle = (): void => {
    toggleRedemption(partyGuid, partyMember, memberAuthToken)
  }

  const actionSection = isApplied ? (
    <div className='text-gray-75 flex items-center gap-4'>
      <span>Applied </span>
      <div>{mode === 'PAYG' && <CancelIcon className='text-secondary' />}</div>
    </div>
  ) : (
    <span className='text-link'>Redeem</span>
  )

  return (
    <div className='pt-2'>
      {error && <ErrorComponent error={error} />}
      <button
        type='button'
        className={cx(styles.rewardButton, {
          [styles.active]: isApplied
        })}
        data-testid='redemption-button'
        disabled={isLoading || (mode === 'TABS' && isApplied)}
        onClick={toggle}
      >
        <div className={cx(styles.reward)}>
          {isLoading ? (
            <Loading variant={LoadingVariant.SECONDARY} />
          ) : (
            <div className={cx(styles.reward)}>
              <div className='flex justify-start'>
                <div
                  className={cx(
                    { 'text-link': isApplied },
                    'pr-4 flex items-center'
                  )}
                >
                  {icon}
                </div>
                <div>
                  <div
                    className={(cx(styles.rewardValue), 'flex justify-start')}
                  >
                    <span>{value}</span>
                  </div>
                  {description && (
                    <div className='text-secondary font-normal '>
                      {description}
                    </div>
                  )}
                </div>
              </div>
              {actionSection}
            </div>
          )}
        </div>
      </button>
    </div>
  )
}
