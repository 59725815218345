// Remove this once we are done testing
/* eslint-disable @typescript-eslint/no-unused-vars */

import * as React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { StockBox } from 'cornucopia-apis'
import { ApolloProvider } from '@apollo/client'
import { client } from '../apollo/apollo-client'
import {
  OOGlobalsProvider,
  getSpaVersion
} from '@local/do-secundo-oo-globals-provider'
import { useInquireLoyalty } from '@local/loyalty-queries'
import { DiscountSelector } from '@local/discount-selector'
import { LoyaltyMessage } from '@local/oo-loyalty-section'
import { Fieldset } from '@local/fieldset'
import {
  LoyaltyConfig,
  PartyMember
} from '../../packages/loyalty/src/LoyaltySection/types'
import { LoyaltyApp } from '../../packages/loyalty/src/LoyaltyApp/LoyaltyApp'

const spaVersion: { current: any; previous: any } = getSpaVersion()

export function AppWrapper({ ooGlobals }: any) {
  return (
    <BrowserRouter basename={ooGlobals.routerBasename}>
      <App ooGlobals={ooGlobals} />
    </BrowserRouter>
  )
}

interface AppProps {
  ooGlobals: OOGlobals
}

interface OOGlobals {
  routerBasename: string
  devError: any
  enabledFeatureFlags: string[]
  gatewayBaseUri: string
  toastwebBaseUri: string
  restaurantGuid: string
  shortUrl: string
  mode: string
  managementGroupGuid: string
  mapboxAccessToken: string
  toastTakeoutCTAEnabled: any
}

export function App({ ooGlobals }: any | AppProps) {
  return (
    <ApolloProvider client={client}>
      <OOGlobalsProvider
        devError={ooGlobals.devError}
        enabledFeatureFlags={ooGlobals.enabledFeatureFlags}
        gatewayBaseUri={ooGlobals.gatewayBaseUri}
        toastwebBaseUri={ooGlobals.toastwebBaseUri}
        restaurantGuid={ooGlobals.restaurantGuid}
        routerBasename={ooGlobals.routerBasename}
        shortUrl={ooGlobals.shortUrl}
        spaVersion={spaVersion.current}
        spaPreviousVersion={spaVersion.previous}
        mode={ooGlobals.mode}
        managementGroupGuid={ooGlobals.managementGroupGuid}
        mapboxAccessToken={ooGlobals.mapboxAccessToken}
        toastTakeoutCTAEnabled={Boolean(ooGlobals.toastTakeoutCTAEnabled)}
      >
        <>
          <StockBox
            stockConfig={{
              shelves: [
                {
                  name: 'corn-loyalty-selector-shelf-fp',
                  components: [
                    {
                      componentId: 'loyalty-lookup-1',
                      component: LoyaltyControllerOpt,
                      priority: 1
                    }
                  ]
                }
              ]
            }}
          />
          <Routes>
            <Route
              path='/checkout'
              element={
                <StockBox
                  stockConfig={{
                    shelves: [
                      {
                        name: 'corn-loyalty-selector-shelf',
                        components: [
                          {
                            componentId: 'loyalty-lookup',
                            component: LoyaltyController,
                            priority: 1
                          }
                        ]
                      }
                    ]
                  }}
                />
              }
            ></Route>
          </Routes>
        </>
      </OOGlobalsProvider>
    </ApolloProvider>
  )
}

const LoyaltyController = ({
  authenticated,
  user,
  cart,
  onLoyaltyUpdate
}: any) => {
  const [inquireLoyalty, { data: inquiryData, loading: inquiryLoading }] =
    useInquireLoyalty()

  const [redemptions, setRedemptions] = React.useState([])
  const [pointsBalance, setPointsBalance] = React.useState([])

  React.useEffect(() => {
    if (authenticated) {
      inquireLoyalty({ cartGuid: cart.guid })
    }
  }, [cart.guid, inquireLoyalty, authenticated])

  React.useEffect(() => {
    if (!inquiryData) return
    setRedemptions(inquiryData.loyaltyInquiryV2?.redemptions || [])
    setPointsBalance(inquiryData.loyaltyInquiryV2?.pointsBalance || [])
  }, [inquiryData])

  return (
    <div style={{ marginTop: '-20px' }}>
      {authenticated ? (
        <>
          {inquiryLoading ? (
            <div>Loading loyalty data...</div>
          ) : (
            <Fieldset
              label={`Rewards ${
                redemptions.length > 0 ? `(${redemptions.length})` : ''
              }`}
              collapsable
            >
              <LoyaltyMessage
                redemptions={redemptions}
                pointsBalance={pointsBalance}
              />
              <DiscountSelector
                cart={cart}
                redemptions={redemptions}
                onLoyaltyUpdate={onLoyaltyUpdate}
              />
            </Fieldset>
          )}
        </>
      ) : (
        <div>Please log in to test 3rd party loyalty</div>
      )}
    </div>
  )
}

const LoyaltyControllerOpt = ({
  authenticated,
  partyMember,
  partyGuid,
  memberAuthToken,
  loyaltyConfig,
  onLoyaltyUpdate,
  mode = 'PAYG',
  loyaltyRedemptionEnabled,
  joinablePartyEnabled
}: {
  authenticated: boolean
  partyMember: PartyMember
  partyGuid: number
  loyaltyConfig: LoyaltyConfig
  memberAuthToken: string
  onLoyaltyUpdate: () => {}
  mode: string
  loyaltyRedemptionEnabled: boolean
  joinablePartyEnabled: boolean
}) => {
  return (
    <LoyaltyApp
      authenticated={authenticated}
      partyMember={partyMember}
      partyGuid={partyGuid}
      memberAuthToken={memberAuthToken}
      loyaltyConfig={loyaltyConfig}
      onLoyaltyUpdate={onLoyaltyUpdate}
      mode={mode}
      loyaltyRedemptionEnabled={loyaltyRedemptionEnabled}
      joinablePartyEnabled={joinablePartyEnabled}
    />
  )
}
