import * as React from 'react'

import {
  AppliedRedemption,
  Cart,
  LoyaltyUpdateCallback,
  RedemptionOffer
} from '../types'
import { useAddLoyaltyRedemption } from '@local/loyalty-queries/src/useAddLoyaltyRedemption'
import { useRemoveLoyaltyRedemption } from '@local/loyalty-queries/src/useRemoveLoyaltyRedemption'
import { Dollars } from '@local/do-secundo-dollars'
import { BaseDiscountCardView } from '../BaseDiscountCardView'

export interface DiscountCardProps {
  testId?: string | number
  redemptionOffer: RedemptionOffer
  appliedRedemptions: AppliedRedemption[]
  cartGuid: string
  onLoyaltyUpdate?: LoyaltyUpdateCallback
  referenceId?: string
}

const findRedemption = (
  redemptionOffer: RedemptionOffer,
  appliedRedemptions: AppliedRedemption[]
) =>
  Boolean(
    appliedRedemptions.find(
      (redemption) =>
        redemption.guid === redemptionOffer.redemptionGuid &&
        redemption.type === redemptionOffer.type &&
        redemption.referenceId === redemptionOffer.referenceId
    )
  )

const useToggleRedemption = (
  redemptionOffer: RedemptionOffer,
  appliedRedemptions: AppliedRedemption[],
  cartGuid: string
): {
  toggleRedemption: () => void
  cart: Cart
  isLoading: boolean
  isApplied: boolean
} => {
  const [cartData, setCartData] = React.useState<{
    cart?: Cart
    isLoading: boolean
    isApplied: boolean
  }>({
    cart: null,
    isLoading: false,
    isApplied: findRedemption(redemptionOffer, appliedRedemptions)
  })

  const isAppliedRef = React.useRef(cartData.isApplied)
  const cartRef = React.useRef<Cart | null>(null)

  React.useEffect(() => {
    cartRef.current = cartData.cart
  }, [cartData.cart])

  const [addRedemption, { loading: addLoading, data: addData }] =
    useAddLoyaltyRedemption()
  const [removeRedemption, { loading: removeLoading, data: removeData }] =
    useRemoveLoyaltyRedemption()

  React.useEffect(() => {
    const isLoading = removeLoading || addLoading
    if (isLoading) {
      setCartData({
        cart: cartRef.current,
        isApplied: isAppliedRef.current,
        isLoading
      })
    } else {
      let cart = cartRef.current
      if (!isAppliedRef.current && addData) {
        cart = addData?.addLoyaltyRedemptionV2
      } else if (isAppliedRef.current && removeData) {
        cart = removeData?.removeLoyaltyRedemptionV2
      }

      if (cart) {
        isAppliedRef.current = findRedemption(
          redemptionOffer,
          cart?.order?.discounts?.loyaltyDiscounts || []
        )
      }

      setCartData({
        cart,
        isApplied: isAppliedRef.current,
        isLoading: false
      })
    }
  }, [
    redemptionOffer,
    appliedRedemptions,
    removeLoading,
    addLoading,
    removeData,
    addData
  ])

  const toggleRedemption = React.useCallback((): void => {
    const { type, referenceId, redemptionGuid } = redemptionOffer

    if (cartData.isApplied) {
      removeRedemption({
        cartGuid,
        redemption: { type, referenceId, guid: redemptionGuid }
      })
    } else {
      addRedemption({
        cartGuid,
        redemption: { type, referenceId, guid: redemptionGuid }
      })
    }
  }, [addRedemption, cartGuid, cartData, redemptionOffer, removeRedemption])

  return {
    toggleRedemption,
    cart: cartData.cart,
    isLoading: cartData.isLoading,
    isApplied: cartData.isApplied
  }
}

/**
 * DiscountCard component
 * Card for displaying loyalty discounts
 */
export const DiscountCard = ({
  testId = 'DiscountCard',
  redemptionOffer,
  appliedRedemptions,
  cartGuid,
  onLoyaltyUpdate
}: DiscountCardProps) => {
  const { toggleRedemption, isLoading, isApplied, cart } = useToggleRedemption(
    redemptionOffer,
    appliedRedemptions,
    cartGuid
  )

  React.useEffect(() => {
    if (onLoyaltyUpdate && cart) {
      onLoyaltyUpdate(cart)
    }
  }, [onLoyaltyUpdate, cart])

  // Fix for now until we get a typed version of Dollars in do-secundo
  const DollarsTs: any = Dollars

  const title = redemptionOffer.name || (
    <>
      <DollarsTs amount={redemptionOffer.redemptionAmount} /> off
    </>
  )
  return (
    <BaseDiscountCardView
      data-testid={testId}
      isLoading={isLoading}
      isApplied={isApplied}
      onClick={toggleRedemption}
      loyaltyType={redemptionOffer.type}
      title={title}
    />
  )
}
