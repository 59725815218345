import * as React from 'react'

export interface PointsProps {
  points: number
}

/**
 * Points component
 *
 * Determines singluarity or plurality of word 'point'
 */
export const Points = ({ points }: PointsProps) => {
  return <div data-testid='points'>{points === 1 ? 'point' : 'points'}</div>
}
