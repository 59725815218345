import * as React from 'react'
import { CancelIcon } from '@toasttab/buffet-pui-icons'

import { GeneralRewardIcon } from '../GeneralRewardIcon'

export interface BaseDiscountCardViewProps {
  testId?: string | number
  title: string | JSX.Element
  description?: string
  expirationDate?: string
  loyaltyType: string
  isLoading: boolean
  isApplied: boolean
  onClick: () => void
}

/**
 * BaseDiscountCardView component
 * Renderer for basic discount cards. There should be no buisness logic in this component.
 */
export const BaseDiscountCardView = ({
  testId = 'BaseDiscountCardView',
  title,
  description,
  expirationDate = 'Jan 15',
  loyaltyType,
  isLoading,
  isApplied,
  onClick
}: BaseDiscountCardViewProps) => {
  return (
    <div data-testid={testId} className='text-primary-75 pt-2 relative'>
      <div
        className={
          isApplied
            ? 'rounded border-2 border-primary-75 border-solid bg-primary-0'
            : 'bg-gray-0 rounded'
        }
      >
        {isApplied ? <div className='absolute rounded bg-black'></div> : ''}
        <div className='py-3 px-4'>
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <div className='justify-between flex items-center'>
              <div className='flex items-center'>
                <div className='pr-4'>
                  <GeneralRewardIcon
                    title={title}
                    description={description}
                    loyaltyType={loyaltyType}
                  />
                </div>
                <div>
                  <p className='font-medium'>{title}</p>
                  {description && (
                    <div className='text-gray-125 text-sm'>{description}</div>
                  )}
                </div>
              </div>
              <div className='text-right'>
                {isApplied ? (
                  <div className='items-center flex'>
                    <div className='font-medium pr-2 opacity-50'>Applied</div>
                    <CancelIcon />
                  </div>
                ) : (
                  <button
                    type='button'
                    className='pr-0 pt-0 font-medium cursor-pointer bg-transparent border-none text-primary-75'
                    onClick={() => {
                      if (!isLoading) onClick()
                    }}
                  >
                    Redeem
                  </button>
                )}
                {expirationDate && !isApplied ? (
                  <div className='text-gray-125 text-sm'>
                    Exp: {expirationDate}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
