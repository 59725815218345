import React from 'react'
import { LoyaltyParams } from '../LoyaltySection/types'
import { LoyaltySectionTabs } from '../LoyaltySection/LoyaltySectionTabs'
import { LoyaltySectionPayG } from '../LoyaltySection/LoyaltySectionPayG'

interface Params extends LoyaltyParams {
  authenticated: boolean
  loyaltyRedemptionEnabled: boolean
  joinablePartyEnabled: boolean
}
export const LoyaltyApp = ({
  partyMember,
  partyGuid,
  loyaltyConfig,
  memberAuthToken,
  onLoyaltyUpdate,
  mode,
  authenticated,
  loyaltyRedemptionEnabled,
  joinablePartyEnabled
}: Params) => {
  // remove joinablePartyEnabled condition when ready to enable attaching loyalty to tab for groups in OPT
  if (!authenticated || !loyaltyRedemptionEnabled || joinablePartyEnabled) {
    return null
  }

  if (mode === 'TABS') {
    return (
      <LoyaltySectionTabs
        partyMember={partyMember}
        partyGuid={partyGuid}
        loyaltyConfig={loyaltyConfig}
        memberAuthToken={memberAuthToken}
        onLoyaltyUpdate={onLoyaltyUpdate}
        mode={mode}
      />
    )
  }
  return (
    <LoyaltySectionPayG
      partyMember={partyMember}
      partyGuid={partyGuid}
      loyaltyConfig={loyaltyConfig}
      memberAuthToken={memberAuthToken}
      onLoyaltyUpdate={onLoyaltyUpdate}
      mode={mode}
    />
  )
}
