import { useCallback } from 'react'
import {
  DocumentNode,
  FetchResult,
  MutationResult,
  useMutation
} from '@apollo/client'
import {
  OPT_ADD_DISCOUNT_PAYG_V2,
  OPT_REMOVE_DISCOUNT_PAYG_V2,
  OPT_ADD_DISCOUNT_TABS_V2,
  OPT_APPLY_LOYALTY_PAYG_V2,
  OPT_APPLY_LOYALTY_TABS_V2
} from './apollo'

export interface LoyaltyMutationParams {
  partyGuid: number
  partyMemberGuid: number
  memberAuthToken: string
  discountGuid?: number
  discountType?: string
}

export declare type LoyaltyMutationCb = (
  input: LoyaltyMutationParams
) =>
  | Promise<FetchResult<any, Record<string, any>, Record<string, any>>>
  | undefined

export const useLoyaltyMutationBase = (
  graphqlMutation: DocumentNode,
  onLoyaltyUpdate: any,
  dataKey: string
): [LoyaltyMutationCb, MutationResult<any>] => {
  const [loyaltyMutateOriginal, apolloResponse] = useMutation(graphqlMutation, {
    onCompleted: (data) => {
      if (data[dataKey].__typename === 'OPTPartyRefreshV2') {
        onLoyaltyUpdate()
      }
    }
  })

  const loyaltyMutate = useCallback(
    (input = {}) => {
      const options = {
        variables: {
          input
        }
      }
      return loyaltyMutateOriginal(options)
    },
    [loyaltyMutateOriginal]
  )

  return [loyaltyMutate, apolloResponse]
}

export const useAddRedemptionPayG = (onLoyaltyUpdate: () => void) =>
  useLoyaltyMutationBase(
    OPT_ADD_DISCOUNT_PAYG_V2,
    onLoyaltyUpdate,
    'optAddDiscountPayGV2'
  )
export const useRemoveRedemptionPayG = (onLoyaltyUpdate: () => void) =>
  useLoyaltyMutationBase(
    OPT_REMOVE_DISCOUNT_PAYG_V2,
    onLoyaltyUpdate,
    'optRemoveDiscountPayGV2'
  )

export const useAddRedemptionTabs = (onLoyaltyUpdate: () => void) =>
  useLoyaltyMutationBase(
    OPT_ADD_DISCOUNT_TABS_V2,
    onLoyaltyUpdate,
    'optAddDiscountTabsV2'
  )

export const useApplyLoyaltyPayG = (onLoyaltyUpdate: () => void) =>
  useLoyaltyMutationBase(
    OPT_APPLY_LOYALTY_PAYG_V2,
    onLoyaltyUpdate,
    'optApplyLoyaltyPayGV2'
  )

export const useApplyLoyaltyTabs = (onLoyaltyUpdate: () => void) =>
  useLoyaltyMutationBase(
    OPT_APPLY_LOYALTY_TABS_V2,
    onLoyaltyUpdate,
    'optApplyLoyaltyTabsV2'
  )
