import React from 'react'
import {
  useAddRedemptionPayG,
  useAddRedemptionTabs,
  useRemoveRedemptionPayG
} from '../../../use-loyalty'

import { RedemptionData } from '../../types'

const findRedemption = (
  redemption: RedemptionData,
  appliedDiscounts: RedemptionData[]
) =>
  Boolean(
    appliedDiscounts?.find(
      (discount) =>
        discount?.referenceId === redemption.referenceId &&
        discount.itemId === redemption.itemId
    )
  )

export const useToggleRedemption = (
  redemption: any,
  appliedRedemptions: any[],
  onLoyaltyUpdate: () => void,
  mode: string
) => {
  const [isApplied, setIsApplied] = React.useState(() => {
    return findRedemption(redemption, appliedRedemptions)
  })

  const [addRedemption, { loading: addLoading, error: addError }] =
    useAddRedemptionPayG(() => {
      setIsApplied(true)
      onLoyaltyUpdate()
    })

  const [removeRedemption, { loading: removeLoading, error: removeError }] =
    useRemoveRedemptionPayG(() => {
      setIsApplied(false)
      onLoyaltyUpdate()
    })

  const [addRedemptionTabs, { loading: addLoadingTabs, error: addErrorTabs }] =
    useAddRedemptionTabs(() => {
      setIsApplied(true)
      onLoyaltyUpdate()
    })

  const toggleRedemption = (
    partyGuid: any,
    partyMember: any,
    memberAuthToken: any
  ) => {
    const input = {
      partyGuid,
      partyMemberGuid: partyMember.partyMemberGuid,
      memberAuthToken,
      discountGuid: redemption.itemId,
      discountType: redemption.referenceId
    }
    if (isApplied) {
      removeRedemption(input)
    } else {
      if (mode === 'TABS') {
        addRedemptionTabs(input)
      } else {
        addRedemption(input)
      }
    }
  }

  return {
    toggleRedemption,
    error: addError || removeError || addErrorTabs,
    isLoading: addLoading || removeLoading || addLoadingTabs,
    isApplied
  }
}
