import React from 'react'

import { LockLockedIcon } from '@toasttab/buffet-pui-icons'
import cx from 'classnames'

import styles from '../LoyaltyRedemption/LoyaltyRedemption.module.css'
import { RedemptionData } from '../../types'

export const LockedLoyaltyRedemption = ({
  redemption
}: {
  redemption: RedemptionData
}) => {
  const messages = redemption?.currentState?.messages
  return (
    <div className='pt-2' data-testid='locked-redemption'>
      <button
        data-testid='reward-text'
        disabled={true}
        className={styles.rewardButton}
      >
        <div className='flex items-center justify-between '>
          <div className='flex items-center'>
            <div>{<LockLockedIcon />}</div>
            <div className='m-4'>
              <div className={(cx(styles.rewardValue), 'flex justify-start')}>
                <span>{messages?.rewardValueMessage}</span>
              </div>

              <div className='text-secondary font-normal '>
                {messages?.rewardLabelMessage}
              </div>
            </div>
          </div>
          <div>{messages?.nextRewardMessage}</div>
        </div>
      </button>
    </div>
  )
}
