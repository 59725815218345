import * as React from 'react'
import { DiscountCard } from '../DiscountCard'
import { LoyaltyUpdateCallback, RedemptionOffer } from '../types'

export interface DiscountSelectorProps {
  testId?: string | number
  cart: any
  redemptions: RedemptionOffer[]
  onLoyaltyUpdate: LoyaltyUpdateCallback
}

/**
 * DiscountSelector component
 * Selection component
 */
export const DiscountSelector = ({
  cart,
  redemptions,
  onLoyaltyUpdate,
  testId = 'DiscountSelector'
}: DiscountSelectorProps) => {
  return (
    <div data-testid={testId}>
      <div>
        {redemptions.map((offer) => (
          <DiscountCard
            redemptionOffer={offer}
            cartGuid={cart.guid}
            appliedRedemptions={cart?.order?.discounts?.loyaltyDiscounts || []}
            onLoyaltyUpdate={onLoyaltyUpdate}
            key={`${offer.redemptionGuid}.${offer.referenceId || offer.type}`}
          />
        ))}
      </div>
    </div>
  )
}
