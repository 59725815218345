import {
  DocumentNode,
  FetchResult,
  MutationFunctionOptions,
  MutationResult,
  useMutation
} from '@apollo/client'
import { useCallback } from 'react'

export interface LoyaltyParams {
  cartGuid: string
  [x: string]: any
}

export declare type LoyaltyMutationCb = (
  params: LoyaltyParams,
  mutationOptions?: MutationFunctionOptions
) =>
  | Promise<FetchResult<any, Record<string, any>, Record<string, any>>>
  | undefined

export const useLoyaltyMutationBase = (
  graphqlMutation: DocumentNode
): [LoyaltyMutationCb, MutationResult<any>] => {
  const [loyaltyMutateOriginal, apolloResponse] = useMutation(graphqlMutation)
  const loyaltyMutate = useCallback(
    (params: LoyaltyParams, mutationOptions: MutationFunctionOptions = {}) => {
      if (!params.cartGuid) return
      const options = {
        ...mutationOptions,
        variables: {
          input: {
            ...(mutationOptions.variables?.input || {}),
            ...params
          }
        }
      }
      return loyaltyMutateOriginal(options)
    },
    [loyaltyMutateOriginal]
  )

  return [loyaltyMutate, apolloResponse]
}
