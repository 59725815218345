import * as React from 'react'
import cx from 'classnames'
import styles from './LoyaltyMessage.module.css'
import { Points } from '@local/points'
export interface LoyaltyMessageProps {
  testId?: string | number
}

/**
 * LoyaltyMessage component
 * Congratualations message for loyalty
 */
export const LoyaltyMessage = ({ redemptions, pointsBalance }: any) => {
  return (
    <div>
      {pointsBalance > 0 ? (
        <div className={styles.box}>
          <div className={cx(styles.text, styles.congratsText)}>
            Congratulations!
          </div>
          <p className={cx(styles.text, styles.points)}>
            You have {pointsBalance} <Points points={pointsBalance} /> and
            unlocked {redemptions.length} reward
            {redemptions.length > 1 ? 's' : ''}
          </p>
        </div>
      ) : (
        <div className={styles.text}>
          You have {pointsBalance}, keep earning points for your next reward!
        </div>
      )}
    </div>
  )
}
