import React, { useState } from 'react'
import cx from 'classnames'

import { ExpandMoreIcon } from '@toasttab/buffet-pui-icons'

import styles from './Fieldset.module.css'

export interface FieldsetProps {
  label: React.ReactNode
  children: React.ReactNode
  instructions?: React.ReactNode
  collapsable?: boolean
  collapsed?: boolean
}

export const Fieldset = ({
  label,
  children,
  instructions,
  collapsable = false,
  collapsed: initialCollapsed = false
}: FieldsetProps) => {
  const [collapsed, collapse] = useState(collapsable && initialCollapsed)
  return (
    <fieldset
      data-testid='fieldset-group'
      className={cx(styles.fieldset, { [styles.collapsed]: collapsed })}
    >
      <legend data-testid='fieldset-legend' className={styles.legend}>
        <div
          data-testid='fieldset-collapser'
          className={styles.flex}
          onClick={() => collapsable && collapse(!collapsed)}
        >
          <span>
            <div
              data-testid='fieldset-label'
              className={cx(styles.label, 'type-default')}
            >
              {label}
            </div>
            {instructions ? (
              <span
                data-testid='fieldset-instructions'
                className={styles.instructions}
              >
                {instructions}
              </span>
            ) : null}
          </span>
          {collapsable ? (
            <ExpandMoreIcon size='sm' className={cx(styles.dropDown)} />
          ) : null}
        </div>
      </legend>
      <div data-testid='fieldset-children' className={styles.children}>
        {children}
      </div>
    </fieldset>
  )
}
