import React, { useEffect } from 'react'
import { LoyaltyParams } from './types'
import { LoyaltySection } from './LoyaltySection'
import { useApplyLoyaltyTabs } from '../use-loyalty'
export const LoyaltySectionTabs = ({
  partyMember,
  partyGuid,
  loyaltyConfig,
  memberAuthToken,
  onLoyaltyUpdate,
  mode
}: LoyaltyParams) => {
  const [applyLoyalty] = useApplyLoyaltyTabs(() => {
    onLoyaltyUpdate()
  })
  useEffect(() => {
    const input = {
      partyGuid,
      partyMemberGuid: partyMember.partyMemberGuid,
      memberAuthToken
    }
    applyLoyalty(input)
  }, [applyLoyalty, partyGuid, partyMember, memberAuthToken])

  return (
    <div data-testid='loyalty-section-tabs'>
      <LoyaltySection
        partyMember={partyMember}
        partyGuid={partyGuid}
        loyaltyConfig={loyaltyConfig}
        memberAuthToken={memberAuthToken}
        onLoyaltyUpdate={onLoyaltyUpdate}
        mode={mode}
      />
    </div>
  )
}
