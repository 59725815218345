import * as React from 'react'
import { GiftCardIcon, RewardsIcon } from '@toasttab/buffet-pui-icons'

export interface GeneralRewardIconProps {
  title: string | JSX.Element
  description?: string
  loyaltyType: string
}

/**
 * GeneralRewardIcon component
 * Rewards icon that will determine different icons to display
 */
export const GeneralRewardIcon = ({
  title,
  description,
  loyaltyType
}: GeneralRewardIconProps) => {
  const titleString = String(title)
  const birthdayRe = /birthday/i

  const isBirthdayReward =
    loyaltyType === 'BIRTHDAY_REWARD' ||
    Boolean(titleString.match(birthdayRe) || description?.match(birthdayRe))

  return (
    <div>
      {loyaltyType === 'INTEGRATION' ? (
        isBirthdayReward ? (
          <div>
            <GiftCardIcon />
          </div>
        ) : (
          <div>
            <RewardsIcon />
          </div>
        )
      ) : (
        ''
      )}
    </div>
  )
}
